import React, { useMemo, useEffect, useState } from 'react'
// import { fetchPoolsAPR } from 'utils/api';
import { Interface } from 'ethers/lib/utils'
import PoolStats from './PoolStats'
import { Presets } from '../../../../../../state/mintV3/reducer'
import { useV3MintActionHandlers } from '../../../../../../state/mintV3/hooks'
import { PoolState } from '../../../../../../hooks/v3/usePools'
import Spinner from '../../../../../common/Spinner'
import { computePoolAddress } from '../../../../../../hooks/v3/computePoolAddress'
import { POOL_DEPLOYER_ADDRESS } from '../../../../../../config/constants/v3/addresses'
import { useMultipleContractSingleData } from '../../../../../../state/multicall/v3/hooks'
import { gammaHypervisorAbi } from '../../../../../../config/abi/v3'
import { useSearchParams } from 'react-router-dom'
import { getAddress } from '../../../../../../utils/addressHelpers'
import DropDown from '../../../../../common/Dropdown'

const PresetProfits = {
  VERY_LOW: 'VERY_LOW',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
}

export const PresetRanges = ({
  mintInfo,
  baseCurrency,
  quoteCurrency,
  isStablecoinPair,
  activePreset,
  handlePresetRangeSelection,
  priceLower,
  price,
  priceUpper,
  isGamma = false,
  gammaPairs,
}) => {
  const { onChangePresetRange } = useV3MintActionHandlers(mintInfo.noLiquidity)
  const [aprs, setAprs] = useState()
  const [searchParams] = useSearchParams()
  const [init, setInit] = useState(false)

  useEffect(() => {
    // fetchPoolsAPR().then(setAprs);
    setAprs([])
  }, [])

  const gammaPairAddresses = gammaPairs ? gammaPairs.map((pair) => pair.address) : []

  const gammaBaseLowerData = useMultipleContractSingleData(gammaPairAddresses, new Interface(gammaHypervisorAbi), 'baseLower')

  const gammaBaseUpperData = useMultipleContractSingleData(gammaPairAddresses, new Interface(gammaHypervisorAbi), 'baseUpper')

  const gammaCurrentTickData = useMultipleContractSingleData(gammaPairAddresses, new Interface(gammaHypervisorAbi), 'currentTick')

  const gammaBaseLowers = gammaBaseLowerData.map((callData) => {
    if (!callData.loading && callData.result && callData.result.length > 0) {
      return Number(callData.result[0])
    }
    return
  })

  const gammaBaseUppers = gammaBaseUpperData.map((callData) => {
    if (!callData.loading && callData.result && callData.result.length > 0) {
      return Number(callData.result[0])
    }
    return
  })

  const gammaCurrentTicks = gammaCurrentTickData.map((callData) => {
    if (!callData.loading && callData.result && callData.result.length > 0) {
      return Number(callData.result[0])
    }
    return
  })

  const gammaValues = gammaPairAddresses.map((_, index) => {
    if (gammaBaseLowers.length >= index && gammaBaseUppers.length >= index && gammaCurrentTicks.length >= index) {
      const gammaBaseLower = gammaBaseLowers[index]
      const gammaCurrentTick = gammaCurrentTicks[index]
      const gammaBaseUpper = gammaBaseUppers[index]
      if (gammaBaseLower && (gammaCurrentTick === 0 || gammaCurrentTick) && gammaBaseUpper) {
        const lowerValue = Math.pow(1.0001, gammaBaseLower - gammaCurrentTick)
        const upperValue = Math.pow(1.0001, gammaBaseUpper - gammaCurrentTick)
        return { min: lowerValue, max: upperValue }
      }
      return
    }
    return
  })

  const ranges = useMemo(() => {
    if (isGamma) {
      return gammaPairs
        ? gammaPairs.map((pair, index) => {
            const gammaValue = gammaValues[index]

            return {
              type: pair.type,
              title: pair.title,
              strategist: pair.strategist,
              address: pair.address,
              min: gammaValue ? gammaValue.min : 0,
              max: gammaValue ? gammaValue.max : 0,
              risk: PresetProfits.VERY_LOW,
              profit: PresetProfits.HIGH,
              allowed0: pair.allowedToken0,
              allowed1: pair.allowedToken1,
            }
          })
        : []
    }

    if (isStablecoinPair)
      return [
        {
          type: Presets.FULL,
          manual: true,
          title: 'Full range',
          min: 0,
          max: Infinity,
          risk: PresetProfits.VERY_LOW,
          profit: PresetProfits.VERY_LOW,
        },
        {
          type: Presets.STABLE,
          manual: true,
          title: 'Stable',
          min: 0.989,
          max: 1.019,
          risk: PresetProfits.VERY_LOW,
          profit: PresetProfits.HIGH,
        },
        {
          type: Presets.ONETICK,
          manual: true,
          title: 'One Tick',
          min: 0.999,
          max: 1,
          risk: PresetProfits.VERY_LOW,
          profit: PresetProfits.HIGH,
        },
      ]

    if (mintInfo)
      return [
        {
          type: Presets.FULL,
          manual: true,
          title: 'Full range',
          min: 0,
          max: Infinity,
          risk: PresetProfits.VERY_LOW,
          profit: PresetProfits.VERY_LOW,
        },
        {
          type: Presets.SAFE,
          manual: true,
          title: 'Safe',
          min: 0.8,
          max: 1.4,
          risk: PresetProfits.LOW,
          profit: PresetProfits.LOW,
        },
        {
          type: Presets.NORMAL,
          manual: true,
          title: 'Common',
          min: 0.9,
          max: 1.2,
          risk: PresetProfits.MEDIUM,
          profit: PresetProfits.MEDIUM,
        },
        {
          type: Presets.RISK,
          manual: true,
          title: 'Expert',
          min: 0.95,
          max: 1.1,
          risk: PresetProfits.HIGH,
          profit: PresetProfits.HIGH,
        },
      ]

    return null
  }, [isStablecoinPair, isGamma, gammaPairs, gammaValues])

  const risk = useMemo(() => {
    if (!priceUpper || !priceLower || !price) return

    const upperPercent = 100 - (+price / +priceUpper) * 100
    const lowerPercent = Math.abs(100 - (+price / +priceLower) * 100)

    const rangePercent = +priceLower > +price && +priceUpper > 0 ? upperPercent - lowerPercent : upperPercent + lowerPercent

    if (rangePercent < 7.5) {
      return 5
    } else if (rangePercent < 15) {
      return (15 - rangePercent) / 7.5 + 4
    } else if (rangePercent < 30) {
      return (30 - rangePercent) / 15 + 3
    } else if (rangePercent < 60) {
      return (60 - rangePercent) / 30 + 2
    } else if (rangePercent < 120) {
      return (120 - rangePercent) / 60 + 1
    } else {
      return 1
    }
  }, [price, priceLower, priceUpper])

  const _risk = useMemo(() => {
    const res = []
    const split = risk?.toString().split('.')

    if (!split) return

    for (let i = 0; i < 5; i++) {
      if (i < +split[0]) {
        res.push(100)
      } else if (i === +split[0]) {
        res.push(parseFloat('0.' + split[1]) * 100)
      } else {
        res.push(0)
      }
    }

    return res
  }, [risk])

  const feeString = useMemo(() => {
    if (mintInfo.poolState === PoolState.INVALID || mintInfo.poolState === PoolState.LOADING) return <Spinner />

    if (mintInfo.noLiquidity) return `0.01% fee`

    return `${(mintInfo.dynamicFee / 10000).toFixed(3)}% fee`
  }, [mintInfo])

  const aprString = useMemo(() => {
    if (!aprs || !baseCurrency || !quoteCurrency) return <Spinner />

    const poolAddress = computePoolAddress({
      poolDeployer: getAddress(POOL_DEPLOYER_ADDRESS),
      tokenA: baseCurrency.wrapped,
      tokenB: quoteCurrency.wrapped,
    }).toLowerCase()

    return aprs[poolAddress] ? aprs[poolAddress].toFixed(2) : undefined
  }, [baseCurrency, quoteCurrency, aprs])

  const gammaValuesLoaded = mintInfo.price // TODO: && gammaValues.filter((value) => !value).length === 0

  useEffect(() => {
    if (gammaValuesLoaded && !init) {
      const strategy = searchParams.get('strategy')
      if (strategy) {
        const range = ranges?.find((ele) => ele.title === strategy)
        handlePresetRangeSelection(range || null)
        onChangePresetRange(range || null)
        if (!range && ranges?.length === 1) {
          handlePresetRangeSelection(range[0])
          onChangePresetRange(range[0])
          setInit(true)
        }
        range && setInit(true)
        return
      }
      if (isGamma) {
        handlePresetRangeSelection(ranges[0] || null)
        onChangePresetRange(ranges[0] || null)
        setInit(true)
        return
      }
      handlePresetRangeSelection(null)
      onChangePresetRange(null)
      setInit(true)
    }
  }, [gammaValuesLoaded, searchParams, ranges, isGamma])

  const options = ranges.map((item) => {
    return { type: item.address, display: item.title }
  })

  return (
    <div className='mt-2'>
      <div className='flex space-x-3'>
        {(isGamma && !gammaValuesLoaded) || !ranges ? (
          <div className='flex justify-center w-full'>
            <Spinner />
          </div>
        ) : (
          <div className='grid w-full grid-cols-1 sm:grid-cols-2 row-auto gap-2'>
            {
              // TODO: This to dropdown
              // eslint-disable-next-line no-constant-condition
              isGamma && false ? (
                <DropDown
                  className={'mt-2 mb-2'}
                  options={options}
                  selected={activePreset?.address}
                  setSelected={(selected) => handlePresetRangeSelection(ranges?.find((range) => range.address === selected))}
                  fullWidth
                  capitalized
                />
              ) : (
                ranges.map((range, i) => (
                  <button
                    onClick={() => {
                      if (activePreset?.title === range.title) {
                        handlePresetRangeSelection(null)
                      } else {
                        handlePresetRangeSelection(range)
                      }
                      onChangePresetRange(range)
                    }}
                    className={`flex items-center justify-center w-full h-[36px] rounded-2xl text-white ${
                      activePreset?.title === range.title ? 'bg-themeOrange font-bold' : 'bg-white/5'
                    } text-xs md:text-sm `}
                    key={i}
                  >
                    {range.title}
                  </button>
                ))
              )
            }
          </div>
        )}
      </div>
      {!isGamma && mintInfo && (
        <div className='flex justify-between space-x-3 mt-[14px]'>
          {baseCurrency && quoteCurrency && (
            <PoolStats
              fee={feeString}
              apr={aprString}
              loading={mintInfo.poolState === PoolState.LOADING || mintInfo.poolState === PoolState.INVALID}
              noLiquidity={mintInfo.noLiquidity}
            ></PoolStats>
          )}
          {_risk && !mintInfo.invalidRange && !isStablecoinPair && (
            <div className='w-1/2 px-2.5 md:px-5 py-2 md:py-3 rounded-xl'>
              <div className='flex mt-1 items-center justify-between'>
                <span className='text-[13px] md:text-[15px] text-white leading-4 md:leading-[19px]'>Risk:</span>
                <div className='flex items-center'>
                  {[1, 2, 3, 4, 5].map((_, i) => (
                    <div key={i} className='w-[13px] h-[13px] ml-[5px] rounded-[100%] overflow-hidden bg-placeholder'>
                      <div key={`risk-${i}`} className={`relative h-[13px] bg-error`} style={{ left: `calc(-100% + ${_risk[i]}%)` }} />
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex mt-1 items-center justify-between'>
                <span className='text-[13px] md:text-[15px] text-white leading-4 md:leading-[19px]'>Profit:</span>
                <div className='flex items-center'>
                  {[1, 2, 3, 4, 5].map((_, i) => (
                    <div key={i} className='w-[13px] h-[13px] ml-[5px] rounded-[100%] overflow-hidden bg-placeholder'>
                      <div key={`profit-${i}`} className={`relative h-[13px] bg-success`} style={{ left: `calc(-100% + ${_risk[i]}%)` }} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
