import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useActivePreset, useV3MintActionHandlers } from '../../state/mintV3/hooks'
import { useGammaUNIProxy } from '../useContract'
import useWeb3, { useWeb3Wagmi } from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'
import { getERC20Contract, getGaugeContract, getIchiVaultContract, getIchiVaultGuardContract, getWBNBContract } from '../../utils/contractHelpers'
import { getAllowance, sendContract } from '../../utils/api'
import { MAX_UINT256, formatAmount, fromWei, toWei } from '../../utils/formatNumber'
import { getIchiVaultDeployer, getIchiVaultGuard } from '../../utils/addressHelpers'
import { BigNumber } from 'ethers'
import { customNotify } from '../../utils/notify'

const useIchiAdd = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const gammaUNIProxyContract = useGammaUNIProxy()
  const web3 = useWeb3()
  const preset = useActivePreset()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const handleIchiAdd = useCallback(
    async (amountA, amountB, amountToWrap, ichiPair) => {
      // TODO: Enhance this
      const allowedA = amountA?.currency?.wrapped?.address?.toLowerCase() === ichiPair?.depositToken?.toLowerCase()
      const allowedB = amountB?.currency?.wrapped?.address?.toLowerCase() === ichiPair?.depositToken?.toLowerCase()

      const wbnbContract = getWBNBContract(web3)
      const baseCurrency = allowedA && !allowedB ? amountA?.currency : amountB?.currency
      const amount = allowedA && !allowedB ? amountA : amountB
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
      if (baseCurrencyAddress !== wbnbContract._address.toLowerCase()) amountToWrap = 0
      const gammaPairAddress = ichiPair ? ichiPair.address : undefined
      if (!amountA || !amountB || !gammaPairAddress) return
      const key = uuidv4()
      const wrapuuid = uuidv4()
      const approve0uuid = uuidv4()
      const supplyuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${baseCurrency.symbol} liquidity`,
          transactions: {
            ...(amountToWrap
              ? {
                  [wrapuuid]: {
                    desc: `Wrap ${formatAmount(fromWei(amountToWrap.toString(10)))} ETH for WETH`,
                    status: TransactionType.WAITING,
                    hash: null,
                  },
                }
              : {}),
            [approve0uuid]: {
              desc: `Approve ${baseCurrency.symbol}`,
              status: amountToWrap ? TransactionType.START : TransactionType.WAITING,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      if (amountToWrap) {
        try {
          await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, amountToWrap.toString(10))
        } catch (err) {
          console.log('wrap error :>> ', err)
          setPending(false)
          return
        }
      }

      const approveAddress = gammaPairAddress.toLowerCase() === '0x511481ef0deb10eb5c1e36b72140718c58921265' ? gammaPairAddress : getIchiVaultGuard()
      let isApproved = true
      const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
      const baseAllowance = await getAllowance(baseTokenContract, approveAddress, account)
      if (fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve0uuid, baseTokenContract, 'approve', [approveAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      //TODO: Remove after interact campaign
      if (gammaPairAddress.toLowerCase() === '0x511481ef0deb10eb5c1e36b72140718c58921265') {
        const vaultContract = getIchiVaultContract(web3, gammaPairAddress)
        const pos = baseCurrencyAddress === ichiPair.token0.address.toLowerCase() ? 0 : 1
        const params0 = []
        params0[pos] = amount.numerator.toString()
        params0[2] = account
        params0[pos === 0 ? 1 : 0] = '0'
        try {
          await sendContract(dispatch, key, supplyuuid, vaultContract, 'deposit', params0, account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      } else {
        const vaultGuardContract = getIchiVaultGuardContract(web3)

        let lpAmount = await vaultGuardContract.methods
          .forwardDepositToICHIVault(gammaPairAddress, getIchiVaultDeployer(), baseCurrencyAddress, amount.numerator.toString(), '0', account)
          .call({ from: account })

        let bnLp = BigNumber.from(lpAmount)
        if (bnLp.eq(0)) {
          dispatch(
            updateTransaction({
              key,
              uuid: supplyuuid,
              status: TransactionType.FAILED,
            }),
          )
          customNotify('0 Shares issued - Size is too small', 'error')
          return
        }
        let lpfinalAmount = bnLp.gt(1) ? bnLp.mul(99).div(100) : bnLp

        const params0 = [gammaPairAddress, getIchiVaultDeployer(), baseCurrencyAddress, amount.numerator.toString(), lpfinalAmount, account]
        try {
          await sendContract(dispatch, key, supplyuuid, vaultGuardContract, 'forwardDepositToICHIVault', params0, account)
        } catch (err) {
          console.log('deposit error :>> ', err)
          setPending(false)
          return
        }
      }

      onFieldAInput('')
      onFieldBInput('')
      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added',
        }),
      )
      setPending(false)
    },
    [account, web3, preset],
  )

  const handleIchiAddAndStake = useCallback(
    async (amountA, amountB, amountToWrap, ichiPair) => {
      // TODO: Enhance this
      const allowedA = amountA?.currency?.wrapped?.address?.toLowerCase() === ichiPair?.depositToken?.toLowerCase()
      const allowedB = amountB?.currency?.wrapped?.address?.toLowerCase() === ichiPair?.depositToken?.toLowerCase()

      const wbnbContract = getWBNBContract(web3)
      const baseCurrency = allowedA && !allowedB ? amountA?.currency : amountB?.currency
      const amount = allowedA && !allowedB ? amountA : amountB
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
      const gammaPairAddress = ichiPair ? ichiPair.address : undefined
      if (baseCurrencyAddress !== wbnbContract._address.toLowerCase()) amountToWrap = 0
      if (!amountA || !amountB || !gammaPairAddress) return
      const key = uuidv4()
      const wrapuuid = uuidv4()
      const approve0uuid = uuidv4()
      const supplyuuid = uuidv4()
      const approve2uuid = uuidv4()
      const stakeuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Add ${ichiPair.symbol} liquidity`,
          transactions: {
            ...(amountToWrap
              ? {
                  [wrapuuid]: {
                    desc: `Wrap ${formatAmount(fromWei(amountToWrap.toString(10)))} ETH for WETH`,
                    status: TransactionType.WAITING,
                    hash: null,
                  },
                }
              : {}),
            [approve0uuid]: {
              desc: `Approve ${baseCurrency.symbol}`,
              status: amountToWrap ? TransactionType.START : TransactionType.WAITING,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
            [approve2uuid]: {
              desc: `Approve ${ichiPair.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Stake LP tokens in the gauge`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      if (amountToWrap) {
        try {
          await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, amountToWrap.toString(10))
        } catch (err) {
          console.log('wrap error :>> ', err)
          setPending(false)
          return
        }
      }

      const approveAddress = gammaPairAddress.toLowerCase() === '0x511481ef0deb10eb5c1e36b72140718c58921265' ? gammaPairAddress : getIchiVaultGuard()
      let isApproved = true
      const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
      const baseAllowance = await getAllowance(baseTokenContract, approveAddress, account)
      if (fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve0uuid, baseTokenContract, 'approve', [approveAddress, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      isApproved = true

      const vaultGuardContract = getIchiVaultGuardContract(web3)

      let lpAmount = await vaultGuardContract.methods
        .forwardDepositToICHIVault(gammaPairAddress, getIchiVaultDeployer(), baseCurrencyAddress, amount.numerator.toString(), '0', account)
        .call({ from: account })

      let bnLp = BigNumber.from(lpAmount)
      if (bnLp.eq(0)) {
        dispatch(
          updateTransaction({
            key,
            uuid: supplyuuid,
            status: TransactionType.FAILED,
          }),
        )
        customNotify('0 Shares issued - Size is too small', 'error')
        return
      }
      let lpfinalAmount = bnLp.gt(1) ? bnLp.mul(99).div(100) : bnLp

      const params0 = [gammaPairAddress, getIchiVaultDeployer(), baseCurrencyAddress, amount.numerator.toString(), lpfinalAmount, account]
      try {
        await sendContract(dispatch, key, supplyuuid, vaultGuardContract, 'forwardDepositToICHIVault', params0, account)
      } catch (err) {
        console.log('deposit error :>> ', err)
        setPending(false)
        return
      }

      isApproved = true
      const gammaPairContract = getERC20Contract(web3, gammaPairAddress)
      const pairBalance = await gammaPairContract.methods.balanceOf(account).call()
      const pairAllowance = await getAllowance(gammaPairContract, ichiPair.gauge.address, account)
      if (fromWei(pairAllowance).lt(fromWei(pairBalance))) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approve2uuid, gammaPairContract, 'approve', [ichiPair.gauge.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 2 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve2uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [pairBalance]
      const gaugeContract = getGaugeContract(web3, ichiPair.gauge.address)
      try {
        await sendContract(dispatch, key, stakeuuid, gaugeContract, 'deposit', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }
      onFieldAInput('')
      onFieldBInput('')
      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added And Staked',
        }),
      )
      setPending(false)
    },
    [account, web3, gammaUNIProxyContract, preset],
  )

  const handleStakeIchi = useCallback(
    async (pool, amount, inStakeToken = false) => {
      const key = uuidv4()
      const approveuuid0 = uuidv4()
      const deposituuid = uuidv4()
      const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      setPending(true)

      const baseCurrency = pool.allowed0 && !pool.allowed1 ? pool?.token0 : pool?.token1
      const baseCurrencyAddress = baseCurrency.address.toLowerCase()
      amount = toWei(amount, baseCurrency.decimals).toFixed(0)

      if (!inStakeToken) {
        dispatch(
          openTransaction({
            key,
            title: `Stake ${pool.symbol}`,
            transactions: {
              [approveuuid0]: {
                desc: `Approve ${baseCurrency.symbol}`,
                status: TransactionType.WAITING,
                hash: null,
              },
              [deposituuid]: {
                desc: `Deposit ${baseCurrency.symbol} and get LP`,
                status: TransactionType.START,
                hash: null,
              },
              [approveuuid]: {
                desc: `Approve ${pool.symbol}`,
                status: TransactionType.START,
                hash: null,
              },
              [stakeuuid]: {
                desc: `Stake tokens in the gauge`,
                status: TransactionType.START,
                hash: null,
              },
            },
          }),
        )

        const approveAddress = getIchiVaultGuard()
        let isApproved = true
        const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
        const baseAllowance = await getAllowance(baseTokenContract, approveAddress, account)
        if (fromWei(baseAllowance, baseCurrency.decimals).lt(amount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approveuuid0, baseTokenContract, 'approve', [approveAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setPending(false)
            return
          }
        }
        if (isApproved) {
          dispatch(
            updateTransaction({
              key,
              uuid: approveuuid0,
              status: TransactionType.SUCCESS,
            }),
          )
        }
        isApproved = true

        const vaultGuardContract = getIchiVaultGuardContract(web3)

        let lpAmount = await vaultGuardContract.methods
          .forwardDepositToICHIVault(pool.address, getIchiVaultDeployer(), baseCurrencyAddress, amount.toString(), '0', account)
          .call({ from: account })

        let bnLp = BigNumber.from(lpAmount)
        if (bnLp.eq(0)) {
          dispatch(
            updateTransaction({
              key,
              uuid: deposituuid,
              status: TransactionType.FAILED,
            }),
          )
          customNotify('0 Shares issued - Size is too small', 'error')
          return
        }
        let lpfinalAmount = bnLp.gt(1) ? bnLp.mul(99).div(100) : bnLp

        const params0 = [pool.address, getIchiVaultDeployer(), baseCurrencyAddress, amount.toString(), lpfinalAmount, account]
        try {
          await sendContract(dispatch, key, deposituuid, vaultGuardContract, 'forwardDepositToICHIVault', params0, account)
        } catch (err) {
          console.log('deposit error :>> ', err)
          setPending(false)
          return
        }

        const gammaPairContract = getERC20Contract(web3, pool.address)
        const pairBalance = await gammaPairContract.methods.balanceOf(account).call()
        amount = pairBalance
      } else {
        dispatch(
          openTransaction({
            key,
            title: `Stake ${pool.symbol} in the farm`,
            transactions: {
              [approveuuid]: {
                desc: `Approve ${pool.symbol}`,
                status: TransactionType.START,
                hash: null,
              },
              [stakeuuid]: {
                desc: `Stake tokens in the farm`,
                status: TransactionType.START,
                hash: null,
              },
            },
          }),
        )
        amount = toWei(amount, 18).toFixed(0)
      }
      let isApproved = true
      const poolContract = getERC20Contract(web3, pool.address)
      const pairAllowance = await getAllowance(poolContract, pool.gauge.address, account)
      if (fromWei(pairAllowance).lt(fromWei(amount))) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, poolContract, 'approve', [pool.gauge.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve 2 error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [amount]
      const gaugeContract = getGaugeContract(web3, pool.gauge.address)
      try {
        await sendContract(dispatch, key, stakeuuid, gaugeContract, 'deposit', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }
      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Staked',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onIchiAdd: handleIchiAdd, onIchiAddAndStake: handleIchiAddAndStake, pending, handleStakeIchi }
}

const useIchiRemove = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const preset = useActivePreset()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const handleIchiRemove = useCallback(
    async (pairAddress, baseSymbol, quoteSymbol, amount) => {
      const key = uuidv4()
      const removeuuid = uuidv4()
      setPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Withdraw ${baseSymbol}/${quoteSymbol} liquidity`,
          transactions: {
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const vaultContract = getIchiVaultContract(web3, pairAddress)
      const params0 = [toWei(amount).toFixed(0), account]
      try {
        await sendContract(dispatch, key, removeuuid, vaultContract, 'withdraw', params0, account)
      } catch (err) {
        console.log('unstake error :>> ', err)
        setPending(false)
        return
      }
      onFieldAInput('')
      onFieldBInput('')
      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Removed',
        }),
      )
      setPending(false)
    },
    [account, web3, preset],
  )

  return { onIchiRemove: handleIchiRemove, pending }
}

export { useIchiAdd, useIchiRemove }
