import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PriceFormats } from '..'
import { useInitialUSDPrices, useV3MintActionHandlers, useV3MintState, useActivePreset, useRangeHopCallbacks } from '../../../../../../state/mintV3/hooks'
import { v3LiquidityRangeType } from '../../../../../../v3lib/entities/constants'
import { useStableAssets } from '../../../../../../hooks/v3/useStableAssets'
import { Bound, updateSelectedPreset } from '../../../../../../state/mintV3/actions'
import { useUSDTValue } from '../../../../../../hooks/v3/useUSDTPrice'
import { tryParseAmount } from '../../../../../../v3lib/utils/utils'
import { Presets } from '../../../../../../state/mintV3/reducer'
import PoolWarning from '../components/PoolWarning'
import { PresetRanges } from '../components/PresetRanges'
import { RangeSelector } from '../components/RangeSelector'
import LiquidityChartRangeInput from '../components/LiquidityChartRangeInput'

const SelectRange = ({ currencyA, currencyB, mintInfo, priceFormat, gammaPairs }) => {
  const [fullRangeWarningShown, setFullRangeWarningShown] = useState(true)
  const [init, setInit] = useState(false)
  const { startPriceTypedValue, liquidityRangeType, presetRange } = useV3MintState()
  const { onChangeLiquidityRangeType } = useV3MintActionHandlers(mintInfo.noLiquidity)
  const stableAssets = useStableAssets()

  const dispatch = useDispatch()
  const activePreset = useActivePreset()

  //TODO - create one main isUSD
  const isUSD = useMemo(() => {
    return priceFormat === PriceFormats.USD
  }, [priceFormat])

  useEffect(() => {
    if (gammaPairs) {
      if (!init) {
        onChangeLiquidityRangeType(v3LiquidityRangeType.GAMMA_RANGE)
        setInit(true)
      }
    } else {
      onChangeLiquidityRangeType(v3LiquidityRangeType.MANUAL_RANGE)
    }
  }, [gammaPairs, init])

  const isStablecoinPair = useMemo(() => {
    if (!currencyA || !currencyB) return false

    const stablecoins = stableAssets.map((token) => token.address)

    return stablecoins.includes(currencyA.wrapped.address) && stablecoins.includes(currencyB.wrapped.address)
  }, [currencyA, currencyB, stableAssets])

  // get value and prices at ticks
  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = useMemo(() => {
    return mintInfo.ticks
  }, [mintInfo])

  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = useMemo(() => {
    return mintInfo.pricesAtTicks
  }, [mintInfo])

  const { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange } = useRangeHopCallbacks(
    currencyA ?? undefined,
    currencyB ?? undefined,
    mintInfo.tickSpacing,
    tickLower,
    tickUpper,
    mintInfo.pool,
  )

  const { onLeftRangeInput, onRightRangeInput } = useV3MintActionHandlers(mintInfo.noLiquidity)

  const tokenA = (currencyA ?? undefined)?.wrapped
  const tokenB = (currencyB ?? undefined)?.wrapped

  const isSorted = useMemo(() => {
    return tokenA && tokenB && tokenA.sortsBefore(tokenB)
  }, [tokenA, tokenB])

  const leftPrice = useMemo(() => {
    return isSorted ? priceLower : priceUpper?.invert()
  }, [isSorted, priceLower, priceUpper])

  const rightPrice = useMemo(() => {
    return isSorted ? priceUpper : priceLower?.invert()
  }, [isSorted, priceUpper, priceLower])

  const price = useMemo(() => {
    if (!mintInfo.price) return

    return mintInfo.invertPrice ? mintInfo.price.invert().toSignificant(5) : mintInfo.price.toSignificant(5)
  }, [mintInfo])

  const currentPriceInUSD = useUSDTValue(tryParseAmount(Number(price).toFixed(5), currencyB ?? undefined), true)

  const handlePresetRangeSelection = useCallback(
    (preset) => {
      if (!price) return

      dispatch(updateSelectedPreset({ preset: preset }))

      if (preset && preset.type === Presets.FULL) {
        setFullRangeWarningShown(true)
        getSetFullRange()
      } else {
        setFullRangeWarningShown(false)
        onLeftRangeInput(preset ? String(+price * preset.min) : '')
        onRightRangeInput(preset ? String(+price * preset.max) : '')
      }
    },
    [dispatch, getSetFullRange, onLeftRangeInput, onRightRangeInput, price],
  )

  const initialUSDPrices = useInitialUSDPrices()

  const currentPriceInUSDA = useUSDTValue(
    tryParseAmount(
      mintInfo.price
        ? mintInfo.invertPrice
          ? Number(mintInfo.price.invert().toSignificant(5)).toFixed(5)
          : Number(mintInfo.price.toSignificant(5)).toFixed(5)
        : undefined,
      currencyB ?? undefined,
    ),
    true,
  )

  const currentPriceInUSDB = useUSDTValue(
    tryParseAmount(
      mintInfo.price
        ? mintInfo.invertPrice
          ? Number(mintInfo.price.invert().toSignificant(5)).toFixed(5)
          : Number(mintInfo.price.toSignificant(5)).toFixed(5)
        : undefined,
      currencyA ?? undefined,
    ),
    true,
  )

  const currentPrice = useMemo(() => {
    if (!mintInfo.price) return

    const isInitialInUSD = Boolean(initialUSDPrices.CURRENCY_A && initialUSDPrices.CURRENCY_B)

    let _price

    if (!isUSD) {
      _price =
        isUSD && currentPriceInUSDA
          ? parseFloat(currentPriceInUSDA?.toSignificant(5))
          : mintInfo.invertPrice
          ? parseFloat(mintInfo.price.invert().toSignificant(5))
          : parseFloat(mintInfo.price.toSignificant(5))
    } else {
      if (isInitialInUSD) {
        _price = parseFloat(initialUSDPrices.CURRENCY_A)
      } else if (currentPriceInUSDA) {
        _price = parseFloat(currentPriceInUSDA.toSignificant(5))
      } else if (currentPriceInUSDB) {
        _price = parseFloat(currentPriceInUSDB.toSignificant(5))
      }
    }

    if (Number(_price) <= 0.0001) {
      return `< ${isUSD && (currentPriceInUSDA || isInitialInUSD) ? '$ ' : ''}0.0001`
    } else {
      return `${isUSD && (currentPriceInUSDA || isInitialInUSD) ? '$ ' : ''}${_price}`
    }
  }, [mintInfo.price, mintInfo.invertPrice, initialUSDPrices.CURRENCY_A, initialUSDPrices.CURRENCY_B, isUSD, currentPriceInUSDA, currentPriceInUSDB])

  return (
    <div className='mt-4 md:mt-5'>
      <p className='text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Select Range</p>
      {gammaPairs && (
        <div className='flex items-center space-x-8 mt-[9px] md:mt-2.5 tracking-[0.9px] text-white text-lg leading-5 font-light'>
          <div
            className='flex items-center space-x-1.5 md:space-x-2 cursor-pointer'
            onClick={() => {
              onChangeLiquidityRangeType(v3LiquidityRangeType.GAMMA_RANGE)
            }}
          >
            <div className='md:w-5 md:h-5 w-[18px] h-[18px] flex flex-col items-center justify-center border border-[#B8B6CB] rounded-full'>
              <div
                className={`md:w-2.5 w-2 h-2 md:h-2.5 ${liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE ? 'bg-white' : 'bg-transparent'}  rounded-full`}
              />
            </div>
            <span className='cursor-pointer text-sm md:text-base'>AUTOMATIC</span>
          </div>
          <div
            className='flex items-center space-x-1.5 md:space-x-2 cursor-pointer'
            onClick={() => {
              onChangeLiquidityRangeType(v3LiquidityRangeType.MANUAL_RANGE)
            }}
          >
            <div className='md:w-5 md:h-5 w-[18px] h-[18px] flex flex-col items-center justify-center border border-[#B8B6CB] rounded-full'>
              <div
                className={`md:w-2.5 w-2 h-2 md:h-2.5 ${
                  liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE ? 'bg-white' : 'bg-transparent'
                }  rounded-full`}
              />
            </div>
            <span className='cursor-pointer text-sm md:text-base'>MANUAL</span>
          </div>
        </div>
      )}
      {liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE && (
        <>
          <div className='mt-[7px] flex items-center space-x-[5px] md:space-x-[7px]'>
            <p className='f-f-fg leading-[17px] text-sm md:text-base md:leading-[18px] text-[#8C8AA0]'>Powered by</p>
            <p className='f-f-fg leading-[17px] text-sm md:text-base md:leading-[18px] text-themeOrange'>Automated strategies</p>
          </div>
          <p className='mt-4 md:mt-[22px] text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Select Strategy</p>
        </>
      )}
      <PresetRanges
        mintInfo={mintInfo}
        baseCurrency={currencyA}
        quoteCurrency={currencyB}
        isStablecoinPair={isStablecoinPair}
        activePreset={activePreset}
        handlePresetRangeSelection={handlePresetRangeSelection}
        priceLower={leftPrice?.toSignificant(5)}
        priceUpper={rightPrice?.toSignificant(5)}
        price={price}
        isGamma={liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE}
        gammaPairs={gammaPairs}
      />
      {liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE && presetRange && gammaPairs && (
        <div className='my-2'>
          <div className='text-sm md:text-[15px] text-[#B8B6CB] mt-2 leading-5 md:leading-6 font-light'>
            Liquidity ranges are automatically rebalanced when certain rebalance triggers are met. In determining the width of the ranges, the goal is to
            optimize fee revenue and volumes while taking into account a years’ worth of volatility to control for impermanent loss. Learn more{' '}
            <a
              rel='noreferrer'
              target={'_blank'}
              href='https://lynex.gitbook.io/lynex-docs/protocol-overview/automated-liquidity-management'
              className='text-themeOrange'
            >
              here
            </a>
            .
          </div>
        </div>
      )}
      {liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE && (
        <>
          {mintInfo.price && (
            <div className='flex justify-center w-full md:space-x-6 mt-[10px]'>
              <div className='text-sm md:text-base mt-[7px] md:mt-0 leading-4 md:leading-5 text-[#fff] f-f-fg'>
                {!mintInfo.noLiquidity ? 'Current Price: ' : 'Initial Price: '}
                {currentPrice ?? ''} <span className='text-dimGray'>{currentPrice ? `${currencyB?.symbol} per ${currencyA?.symbol}` : `Loading...`}</span>
              </div>
            </div>
          )}
          <div className='mt-3'>
            <RangeSelector
              priceLower={priceLower}
              priceUpper={priceUpper}
              getDecrementLower={getDecrementLower}
              getIncrementLower={getIncrementLower}
              getDecrementUpper={getDecrementUpper}
              getIncrementUpper={getIncrementUpper}
              onLeftRangeInput={onLeftRangeInput}
              onRightRangeInput={onRightRangeInput}
              currencyA={currencyA}
              currencyB={currencyB}
              mintInfo={mintInfo}
              disabled={!startPriceTypedValue && !mintInfo.price}
            />
          </div>
          {activePreset?.type === Presets.FULL && fullRangeWarningShown && (
            <PoolWarning
              text='Full range positions may earn less fees than concentrated positions.'
              setFullRangeWarningShown={setFullRangeWarningShown}
              isFull={true}
            />
          )}
          {mintInfo.outOfRange && (
            <PoolWarning text='The price range for this liquidity position is not eligible for farming rewards. To become eligible for rewards, please increase your range' />
          )}
          {mintInfo.invalidRange && <PoolWarning text='Invalid Range' />}
        </>
      )}
      {activePreset && (
        <div className='bg-white/5 rounded-[5px] flex justify-center items-center mt-5'>
          {activePreset?.type === 'YieldIQ' ? (
            <p className='text-white text-[12px] leading-[22px] md:leading-6 mt-2 p-4 rounded'>
              Yield IQ vaults enable users to contribute a single token to a Liquidity Pool (LP). The primary goal of this strategy is to grow your initial
              deposit, maintaining its share within the range of [65% - 95%] to minimize exposure to the other token in the LP. Users may receive a portion of
              their funds as the other token in the LP.
            </p>
          ) : (
            <LiquidityChartRangeInput
              currencyA={currencyA ?? undefined}
              currencyB={currencyB ?? undefined}
              feeAmount={mintInfo.dynamicFee}
              ticksAtLimit={mintInfo.ticksAtLimit}
              price={
                priceFormat === PriceFormats.USD
                  ? currentPriceInUSD
                    ? parseFloat(currentPriceInUSD.toSignificant(5))
                    : undefined
                  : price
                  ? parseFloat(price)
                  : undefined
              }
              priceLower={priceLower}
              priceUpper={priceUpper}
              onLeftRangeInput={onLeftRangeInput}
              onRightRangeInput={onRightRangeInput}
              interactive={false}
              priceFormat={priceFormat}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default SelectRange
