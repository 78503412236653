import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useSearchParams } from 'react-router-dom'
import TokenInput from '../../common/Input/TokenInput'
import useWalletModal from '../../../hooks/useWalletModal'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { ZERO_VALUE, formatAmount, fromWei, isInvalidAmount } from '../../../utils/formatNumber'
import { useBestQuoteSwap, useProceedSwap } from '../../../hooks/useSwap'
import StyledButton from '../../common/Buttons/styledButton'
// import SwapPopup from './popups/swapPopup'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import useDebounce from '../../../hooks/useDebounce'
// import Spinner from '../../common/Spinner'
import WarningModal from './WarningModal'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { TransactButton } from '../../../components/common/Buttons/transactButton'
// import Skeleton from '../../common/Spinner/skeleton'

import './swap.scss'
import { fetchImportedToken } from '../../../utils/fetchImportToken'
import { useImportTokens } from '../../../state/application/hooks'

const SwapBest = ({ slippage, deadline }) => {
  const [init, setInit] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [fromAmount, setFromAmount] = useState('')
  const [fromAsset, setFromAsset] = useState(null)
  const [toAsset, setToAsset] = useState(null)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const debouncedFromAmount = useDebounce(fromAmount)
  const { bestTrade, quotePending, bestSwap, swapPending, params, isOpenOcean } = useBestQuoteSwap(fromAsset, toAsset, debouncedFromAmount, slippage, deadline)
  const { onWrap, onUnwrap, swapPending: wrapPending } = useProceedSwap()
  const [searchParams] = useSearchParams()
  const [reverseTransiction, setReverseTransiction] = useState(false)
  const { importedTokens, addImportedToken } = useImportTokens()

  const isTokenImported = useMemo(() => {
    if (toAsset && fromAsset) {
      return importedTokens.some((asset) => asset.address === toAsset.address || asset.address === fromAsset.address)
    } else {
      return false
    }
  }, [fromAsset, toAsset, importedTokens])

  const fetchBestFinished = bestTrade && bestTrade.outAmount !== '0' && !quotePending

  const isWrap = useMemo(() => {
    if (fromAsset && toAsset && fromAsset.address === 'ETH' && toAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const isUnwrap = useMemo(() => {
    if (fromAsset && toAsset && toAsset.address === 'ETH' && fromAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const toAmount = useMemo(() => {
    if (bestTrade && !isWrap && !isUnwrap && toAsset) {
      return fromWei(bestTrade.outAmount, toAsset.decimals).toString(10)
    } else if (isWrap || isUnwrap) {
      return fromAmount
    }
    return ''
  }, [bestTrade, toAsset])

  const btnMsg = useMemo(() => {
    if (!account) {
      return {
        isError: true,
        label: 'CONNECT WALLET',
      }
    }

    if (!fromAsset || !toAsset) {
      return {
        isError: true,
        label: 'SELECT A TOKEN',
      }
    }

    if (isInvalidAmount(fromAmount)) {
      return {
        isError: true,
        label: 'ENTER AN AMOUNT',
      }
    }

    if (fromAsset.balance && fromAsset.balance.lt(fromAmount)) {
      return {
        isError: true,
        label: 'INSUFFICIENT ' + fromAsset.symbol + ' BALANCE',
      }
    }

    if (isWrap) {
      return {
        isError: false,
        label: 'WRAP',
      }
    }

    if (isUnwrap) {
      return {
        isError: false,
        label: 'UNWRAP',
      }
    }

    if (!bestTrade || bestTrade.outAmount === '0') {
      return {
        isError: true,
        label: 'INSUFFICIENT LIQUIDITY FOR THIS TRADE',
      }
    }

    return {
      isError: false,
      label: 'SWAP',
    }
  }, [account, fromAsset, toAsset, fromAmount, bestTrade, isWrap, isUnwrap])

  const baseAssets = useContext(BaseAssetsConetext)

  const firstRun = useRef(true)
  useEffect(() => {
    if (init || baseAssets.length === 0 || !firstRun.current) return
    setInputAndOutput()
    async function fetchToken(address) {
      let token = address ? baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase()) : null
      if (!token && address) {
        token = await fetchImportedToken(address, account)
        if (token) addImportedToken(token)
      }
      return token
    }
    async function setInputAndOutput() {
      firstRun.current = false
      const inputCurrency = searchParams.get('inputCurrency')
      const outputCurrency = searchParams.get('outputCurrency')
      const from = await fetchToken(inputCurrency)
      const to = await fetchToken(outputCurrency)
      if (!from) {
        setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
      } else {
        setFromAsset(from)
      }
      if (!to) {
        setToAsset(baseAssets.find((asset) => asset.symbol === 'LYNX'))
      } else {
        setToAsset(to)
      }
      setInit(true)
    }
  }, [baseAssets, searchParams])

  useEffect(() => {
    // NOTE: Refreshes balances
    if (baseAssets.length === 0) return
    const inputCurrency = fromAsset
    const outputCurrency = toAsset
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.address.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.address.toLowerCase()) : null
    if (!from) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
    } else {
      setFromAsset(from)
    }
    if (!to) {
      setToAsset(baseAssets.find((asset) => asset.symbol === 'LYNX'))
    } else {
      setToAsset(to)
    }
  }, [baseAssets])

  const handleSwap = useCallback(() => {
    bestSwap(...params)
  }, [fromAsset, toAsset, fromAmount, toAmount, params, slippage])

  const priceImpact = useMemo(() => {
    if (fromAsset && toAsset && fromAmount && toAmount && bestTrade) {
      const fromInUsd = new BigNumber(fromAmount).times(fromAsset.price)
      const toInUsd = new BigNumber(toAmount).times(toAsset.price)
      return new BigNumber(((fromInUsd - toInUsd) / fromInUsd) * 100)
    }
    return ZERO_VALUE
  }, [fromAsset, toAsset, fromAmount, toAmount, bestTrade])

  return (
    <div className='swap-wrapper'>
      <div className='fromto'>
        <TokenInput
          title='From'
          asset={fromAsset}
          setAsset={setFromAsset}
          otherAsset={toAsset}
          setOtherAsset={setToAsset}
          amount={fromAmount}
          onInputChange={(val) => {
            setFromAmount(val)
          }}
          isDollar={true}
        />
      </div>
      <div className='flex justify-center -my-4 '>
        <button
          onClick={() => {
            const tempAsset = fromAsset
            if (new BigNumber(toAmount).gt(0)) {
              setFromAmount(toAmount)
            }
            setFromAsset(toAsset)
            setToAsset(tempAsset)
          }}
          className='flex justify-center items-center w-[42px] h-[42px] bg-black/70 border border-[#ffffff33] rounded-full transition duration-200 ease-in-out hover:rotate-180 z-20'
        >
          <img className='w-[20px] h-[20px]' src='/images/swap/reverse-icon.svg' />
        </button>
      </div>
      <div className='fromto'>
        <TokenInput
          title='To'
          asset={toAsset}
          setAsset={setToAsset}
          amount={toAmount}
          otherAsset={fromAsset}
          setOtherAsset={setFromAsset}
          disabled
          isDollar={true}
        />
      </div>

      {isTokenImported && (
        <div className='w-full flex flex-row justify-end my-4'>
          <img alt='' src='/images/svgs/warning.svg' />
          <p className='text-red-500 text-sm mx-2'>WARNING! Token imported; trade at your own risk.</p>
        </div>
      )}

      {account ? (
        <TransactButton
          disabled={btnMsg.isError || swapPending || wrapPending || !fetchBestFinished}
          pending={swapPending || wrapPending || quotePending}
          onClickHandler={() => {
            if (isWrap) {
              onWrap(fromAmount)
            } else if (isUnwrap) {
              onUnwrap(fromAmount)
            } else {
              if (priceImpact.gt(5)) {
                setIsWarning(true)
              } else {
                handleSwap()
              }
            }
          }}
          content={btnMsg.label}
          className='w-full text-base md:text-lg py-3 px-4 mt-4'
        />
      ) : (
        <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='w-full text-base md:text-lg py-3 px-4 mt-4' />
      )}

      {!isWrap && !isUnwrap && (
        <>
          {account && fromAmount && (
            <div className='fromto mt-4 text-gray-400'>
              {priceImpact.gt(0) && (
                <div
                  className={`flex space-x-2 p-1 items-center justify-center border border-[#ffffff33] text-white rounded-xl ${
                    priceImpact.lt(1) ? 'bg-success/15' : priceImpact.lt(2) ? 'bg-white/10' : priceImpact.lt(5) ? 'bg-warn/40' : 'bg-error/40'
                  }`}
                >
                  <p className='text-sm md:text-md leading-5'>Trade Impact:</p>
                  <p className='text-sm md:text-md leading-5 font-semibold'>-{formatAmount(priceImpact)}%</p>
                </div>
              )}

              <>
                <div className='flex items-center justify-between mt-3'>
                  <p className='text-white text-sm md:text-base leading-5'>Rate:</p>
                  <div className='flex items-center space-x-1.5'>
                    <p className='text-white text-sm md:text-base leading-5'>
                      {fetchBestFinished
                        ? reverseTransiction
                          ? `${formatAmount(new BigNumber(toAmount).div(fromAmount))} ${toAsset.symbol} per ${fromAsset.symbol}`
                          : `${formatAmount(new BigNumber(fromAmount).div(toAmount))} ${fromAsset.symbol} per ${toAsset.symbol}`
                        : 'Loading...'}
                    </p>
                    <button onClick={() => setReverseTransiction(!reverseTransiction)}>
                      <img alt='' src='/images/swap/reverse-small-icon.png' />
                    </button>
                  </div>
                </div>
                <div className='mt-[0.3rem]'>
                  <div className='flex items-center justify-between'>
                    <p className='text-white text-sm md:text-base leading-5'>Output value:</p>
                    {fetchBestFinished ? (
                      <p className='text-white text-sm md:text-base leading-5'>
                        {formatAmount(fromWei(bestTrade.outAmount, toAsset.decimals))} {toAsset.symbol}
                        <span className='ml-2 text-xs md:text-sm'>{`($${formatAmount((toAsset?.price || 0) * (Number(toAmount) || 0))})`}</span>
                        <span
                          className={`ml-2 p-1 text-xs items-center justify-center border border-[#ffffff33] text-white rounded-xl ${
                            priceImpact.lt(1) ? 'bg-success/15' : priceImpact.lt(2) ? 'bg-white/10' : priceImpact.lt(5) ? 'bg-warn/40' : 'bg-error/40'
                          }`}
                        >
                          {' '}
                          -{formatAmount(priceImpact)}%
                        </span>
                      </p>
                    ) : (
                      <p className='text-white text-sm md:text-base leading-5'>Loading...</p>
                    )}
                  </div>
                </div>
                <div className='mt-[0.3rem]'>
                  <div className='flex items-center justify-between'>
                    <p className='text-white text-sm md:text-base leading-5'>Minimum received:</p>
                    {fetchBestFinished ? (
                      <p className='text-white text-sm md:text-base leading-5'>
                        {formatAmount(
                          fromWei(bestTrade.outAmount, toAsset.decimals)
                            .times(100 - slippage)
                            .div(100),
                        )}{' '}
                        {toAsset.symbol}
                      </p>
                    ) : (
                      <p className='text-white text-sm md:text-base leading-5'>Loading...</p>
                    )}
                  </div>
                </div>
              </>

              <div className='flex items-center justify-between'>
                <p className='text-white text-sm md:text-base leading-5'>Slippage Tolerance:</p>
                <p className='text-white text-sm md:text-base leading-5'>{slippage}%</p>
              </div>
            </div>
          )}

          {isOpenOcean && (
            <div className='flex items-center justify-center space-x-1 mt-6'>
              <div className='text-white text-[13px] lg:text-[17px] mt-[2px]'>Powered by</div>
              <img src='/images/svgs/openocean.svg' alt='' className='h-6 md:h-8' />
            </div>
          )}

          {account && fromAmount && (
            <>
              {/* <div className='w-full max-w-[550px] mt-2'>
                <div className='fromto'>
                  <div className='text-white font-medium text-xl'>Possible Routes</div>
                  <div className='flex items-center justify-between mt-4'>
                    <div className='flex items-center bg-[#3d3d3d] border border-[#ffffff33] rounded-xl py-[7px] px-[12px] text-[13px] md:text-base text-white cursor-default'>
                      <div className='flex space-x-3'>
                        <p>{formatAmount(fromAmount, true)}</p>
                        {fromAsset.logoURI ? <img src={fromAsset?.logoURI} alt='' className='w-[22px] h-[22px]' /> : <span>{fromAsset?.symbol}</span>}
                      </div>
                    </div>
                    <div className='flex items-center bg-[#3d3d3d] border border-[#ffffff33] rounded-xl py-[7px] px-[12px] text-[13px] md:text-base text-white cursor-default'>
                      <div className='flex space-x-3'>
                        <p>{formatAmount(toAmount, true)}</p>
                        {toAsset.logoURI ? <img src={toAsset?.logoURI} alt='' className='w-[22px] h-[22px]' /> : <span>{toAsset?.symbol}</span>}
                      </div>
                    </div>
                  </div>
                  <div className='px-[22px]'>
                    {trades?.length > 0 &&
                      trades.map((route, index) => {
                        console.log(route)
                        return (
                          <div
                            className={`relative flex py-4 px-4 items-center after:w-[60px] before:absolute before:left-0 before:top-0 before:w-full before:h-[48px] before:rounded-b-[24px] before:border-dashed before:border-[#DEDBF2] before:border-b 
                        ${index === trades.length - 1 ? 'before:border-x' : 'border-x border-dashed border-[#DEDBF2]'}`}
                            key={`route-${index}`}
                          >
                            <div className='relative flex grow px-3'>
                              <div className='w-full flex justify-between overflow-hidden space-x-4 before:content-[""] after:content-[""]'>
                                <div className='px-3 py-2 bg-[#3d3d3d] border border-[#ffffff33] rounded-xl w-fit space-y-1'>
                                  <span className='text-base md:text-[18px] text-white'>{route[0]?.name}</span>
                                  <div className='text-[12px] md:text-[14px] text-white pl-1 space-x-1'>
                                    {route[0]?.outputAmount > 0 && !quotePending ? (
                                      <>
                                        <div className='flex space-x-3'>
                                          {toAsset.logoURI ? (
                                            <img src={toAsset?.logoURI} alt='' className='w-[22px] h-[22px]' />
                                          ) : (
                                            <span>{toAsset?.symbol}</span>
                                          )}

                                          <p>{formatAmount(route[0]?.outputAmount, true)}</p>
                                        </div>
                                        <TransactButton
                                          disabled={btnMsg.isError || swapPending || wrapPending || !fetchBestFinished}
                                          pending={swapPending || wrapPending || quotePending}
                                          onClickHandler={() => {
                                            if (isWrap) {
                                              onWrap(fromAmount)
                                            } else if (isUnwrap) {
                                              onUnwrap(fromAmount)
                                            } else {
                                              if (priceImpact.gt(5)) {
                                                setIsWarning(true)
                                              } else {
                                                route[1](...route[2])
                                              }
                                            }
                                          }}
                                          content={btnMsg.label}
                                          className='w-full text-base md:text-lg py-3 px-4 mt-4'
                                        />
                                      </>
                                    ) : (
                                      <Skeleton />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div> */}
              {/* <div className='w-full max-w-[550px] mt-2'>
                <div className='fromto'>
                  <div className='text-white font-medium text-xl'>Routing</div>
                  <div className='flex items-center justify-between mt-4'>
                    <div className='flex items-center bg-[#3d3d3d] border border-[#ffffff33] rounded-xl py-[7px] px-[12px] text-[13px] md:text-base text-white cursor-default'>
                      <div className='flex space-x-3'>
                        <p>{formatAmount(fromAmount, true)}</p>
                        {fromAsset.logoURI ? <img src={fromAsset?.logoURI} alt='' className='w-[22px] h-[22px]' /> : <span>{fromAsset?.symbol}</span>}
                      </div>
                    </div>
                    <div className='flex items-center bg-[#3d3d3d] border border-[#ffffff33] rounded-xl py-[7px] px-[12px] text-[13px] md:text-base text-white cursor-default'>
                      <div className='flex space-x-3'>
                        <p>{formatAmount(toAmount, true)}</p>
                        {toAsset.logoURI ? <img src={toAsset?.logoURI} alt='' className='w-[22px] h-[22px]' /> : <span>{toAsset?.symbol}</span>}
                      </div>
                    </div>
                  </div>
                  <div className='px-[22px]'>
                    {bestTrade &&
                      bestTrade.path.routes.map((route, index) => {
                        return (
                          <div
                            className={`relative flex py-4 px-4 after:w-[60px] before:absolute before:left-0 before:top-0 before:w-full before:h-[48px] before:rounded-b-[24px] before:border-dashed before:border-[#DEDBF2] before:border-b ${
                              index === bestTrade.path.routes.length - 1 ? 'before:border-x' : 'border-x border-dashed border-[#DEDBF2]'
                            }`}
                            key={`route-${index}`}
                          >
                            <div className='py-2 px-3 rounded-xl bg-[#3d3d3d] border border-[#ffffff33] relative text-white text-base w-fit h-fit mt-[10px]'>
                              {route.percentage}%
                            </div>
                            <div className='relative flex grow px-3'>
                              <div className='w-full flex justify-between overflow-hidden space-x-4 before:content-[""] after:content-[""]'>
                                {route.subRoutes?.map((subRoute, idx) => {
                                  const token = getTokenFromAddress(subRoute.to)
                                  return (
                                    <div className='px-3 py-2 bg-[#3d3d3d] border border-[#ffffff33] rounded-xl w-fit space-y-1' key={`subroute-${idx}`}>
                                      <div className='flex items-center justify-around py-[7px] space-x-[6px]'>
                                        {token.logoURI ? (
                                          <img src={token?.logoURI} alt='' className='w-[22px] h-[22px]' />
                                        ) : (
                                          <span className='text-base md:text-[18px] text-white'>{token?.symbol}</span>
                                        )}
                                      </div>
                                      {subRoute.dexes.map((dex, index) => {
                                        return (
                                          <div className='text-[12px] md:text-[14px] text-white pl-1 space-x-1' key={`dex-${dex.dex}-${index}`}>
                                            <span>{dex.dex === 'LynexV2' ? 'V1' : dex.dex === 'LynexFusion' ? 'FUSION' : dex.dex}</span>
                                            <span>{dex.percentage}%</span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div> */}
            </>
          )}
        </>
      )}
      {isWarning && <WarningModal isOpen={isWarning} setIsOpen={setIsWarning} onClickHandler={handleSwap} priceImpact={priceImpact} />}
    </div>
  )
}

export default SwapBest
