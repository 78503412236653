import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { getAllowance, sendContract } from '../utils/api'
import { getERC20Contract, getOptionsContract, getVeTHEContract, getbveLynxContract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { fromWei, MAX_UINT256, toWei } from '../utils/formatNumber'
import { getOptionAddress } from '../utils/addressHelpers'
import { useTimestamp } from '../utils/time'
import { useVeTHE } from './useContract'

// TODO: Consider giving user option
const DEFAULT_SLIPPAGE = 2

const useExcercise = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleExcercise = useCallback(
    async (principalToken, amount, oLynxAmount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Excercise your oLYNX option`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${principalToken.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Redeem your oLYNX`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isApproved = true
      const tokenContract = getERC20Contract(web3, principalToken.address)
      const allowance = await getAllowance(tokenContract, getOptionAddress(), account)
      if (fromWei(allowance, principalToken.decimals).lt(amount)) {
        isApproved = false
        try {
          // TODO: Reconsider MAX approve
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getOptionAddress(), MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const withSlippage = (amount * (DEFAULT_SLIPPAGE + 100)) / 100
      const params = [toWei(oLynxAmount, 18).toFixed(0), toWei(withSlippage, principalToken.decimals).toFixed(0), account]
      const optionsContract = getOptionsContract(web3)
      try {
        await sendContract(dispatch, key, stakeuuid, optionsContract, 'exercise', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Redemeed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onExcercise: handleExcercise, pending }
}

const useExcerciseVe = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const veTHEContract = useVeTHE()
  const web3 = useWeb3()

  const handleExcercise = useCallback(
    async (principalToken, amount, oLynxAmount) => {
      const key = uuidv4()
      // const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Excercise your oLYNX option`,
          transactions: {
            [stakeuuid]: {
              desc: `Redeem your oLYNX into veLYNX`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      // let isApproved = true
      // TODO: We might eventually have principal here
      // const tokenContract = getERC20Contract(web3, principalToken.address)
      // const allowance = await getAllowance(tokenContract, getOptionAddress(), account)
      // if (fromWei(allowance, principalToken.decimals).lt(amount)) {
      //   isApproved = false
      //   try {
      //     // TODO: Reconsider MAX approve
      //     await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getOptionAddress(), MAX_UINT256], account)
      //   } catch (err) {
      //     console.log('approve error :>> ', err)
      //     setPending(false)
      //     return
      //   }
      // }
      // if (isApproved) {
      //   dispatch(
      //     updateTransaction({
      //       key,
      //       uuid: approveuuid,
      //       status: TransactionType.SUCCESS,
      //     }),
      //   )
      // }
      // TODO: we might actually need dynamimc discount eventually
      const params = [toWei(oLynxAmount, 18).toFixed(0), '0', account, 0, useTimestamp() + 300]
      const optionsContract = getOptionsContract(web3)
      try {
        await sendContract(dispatch, key, stakeuuid, optionsContract, 'exerciseVe', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Redemeed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  const handleExcerciseAndMerge = useCallback(
    async (principalToken, amount, oLynxAmount, targetId) => {
      const key = uuidv4()
      const stakeuuid = uuidv4()
      const mergeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Excercise your oLYNX option`,
          transactions: {
            [stakeuuid]: {
              desc: `Redeem your oLYNX into veLYNX`,
              status: TransactionType.START,
              hash: null,
            },
            [mergeuuid]: {
              desc: `Merge into #${targetId} veLYNX`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      // let isApproved = true
      // TODO: We might eventually have principal here
      // const tokenContract = getERC20Contract(web3, principalToken.address)
      // const allowance = await getAllowance(tokenContract, getOptionAddress(), account)
      // if (fromWei(allowance, principalToken.decimals).lt(amount)) {
      //   isApproved = false
      //   try {
      //     // TODO: Reconsider MAX approve
      //     await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getOptionAddress(), MAX_UINT256], account)
      //   } catch (err) {
      //     console.log('approve error :>> ', err)
      //     setPending(false)
      //     return
      //   }
      // }
      // if (isApproved) {
      //   dispatch(
      //     updateTransaction({
      //       key,
      //       uuid: approveuuid,
      //       status: TransactionType.SUCCESS,
      //     }),
      //   )
      // }
      // TODO: we might actually need dynamimc discount eventually
      const params = [toWei(oLynxAmount, 18).toFixed(0), '0', account, 0, useTimestamp() + 300]
      const optionsContract = getOptionsContract(web3)
      try {
        await sendContract(dispatch, key, stakeuuid, optionsContract, 'exerciseVe', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }
      const veLynxContract = getVeTHEContract(web3)
      const balanceOfAccount = await veLynxContract.methods.balanceOf(account).call()
      const currentTokenId = await veLynxContract.methods.tokenOfOwnerByIndex(account, balanceOfAccount - 1).call()
      try {
        await sendContract(dispatch, key, mergeuuid, veTHEContract, 'merge', [currentTokenId, targetId], account)
      } catch (err) {
        console.log('merge error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Redemeed',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract],
  )

  return { onExcerciseVe: handleExcercise, pending, handleExcerciseAndMerge }
}

const useExcercisebveLynx = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const veLynxContract = getVeTHEContract(web3)

  const handleExcerciseBveLynx = useCallback(
    async (principalToken, amount, oLynxAmount) => {
      const key = uuidv4()
      const stakeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim your bveLYNX`,
          transactions: {
            [stakeuuid]: {
              desc: `Redeem your bveLYNX into veLYNX`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      const params = [toWei(oLynxAmount, 18).toFixed(0), '0', account, useTimestamp() + 120]
      const optionsContract = getbveLynxContract(web3)
      try {
        await sendContract(dispatch, key, stakeuuid, optionsContract, 'exerciseVe', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Redemeed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  const handleExcerciseBveLynxAndMerge = useCallback(
    async (principalToken, amount, oLynxAmount, targetId) => {
      const key = uuidv4()
      const stakeuuid = uuidv4()
      const mergeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim your bveLYNX`,
          transactions: {
            [stakeuuid]: {
              desc: `Redeem your bveLYNX into veLYNX`,
              status: TransactionType.START,
              hash: null,
            },
            [mergeuuid]: {
              desc: `Merge your bveLYNX into veLYNX # ${targetId}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      const params = [toWei(oLynxAmount, 18).toFixed(0), '0', account, useTimestamp() + 120]
      const optionsContract = getbveLynxContract(web3)
      try {
        await sendContract(dispatch, key, stakeuuid, optionsContract, 'exerciseVe', params, account)
      } catch (err) {
        console.log('claim error :>> ', err)
        setPending(false)
        return
      }

      const balanceOfAccount = await veLynxContract.methods.balanceOf(account).call()
      const currentTokenId = await veLynxContract.methods.tokenOfOwnerByIndex(account, balanceOfAccount - 1).call()
      try {
        await sendContract(dispatch, key, mergeuuid, veTHEContract, 'merge', [currentTokenId, targetId], account)
      } catch (err) {
        console.log('merge error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Redemeed',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract, veLynxContract],
  )

  return { onExcercisebveLynx: handleExcerciseBveLynx, pending, handleExcerciseBveLynxAndMerge }
}

const useExcerciseLp = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleExcercise = useCallback(
    async (principalToken, maxPaymentAmount, oLynxAmount, lpAmount, discount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Excercise your oLYNX LP option`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${principalToken.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Redeem your oLYNX`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isApproved = true
      const tokenContract = getERC20Contract(web3, principalToken.address)
      const allowance = await getAllowance(tokenContract, getOptionAddress(), account)
      if (fromWei(allowance, principalToken.decimals).lt(parseInt(maxPaymentAmount) + parseInt(lpAmount) + 1)) {
        isApproved = false
        try {
          // TODO: Reconsider MAX approve
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getOptionAddress(), MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const withSlippage = (maxPaymentAmount * (DEFAULT_SLIPPAGE + 100)) / 100
      const withSlippageLp = (lpAmount * (DEFAULT_SLIPPAGE + 100)) / 100
      const deadline = useTimestamp() + 120
      const params = [
        toWei(oLynxAmount, 18).toFixed(0),
        toWei(withSlippage, principalToken.decimals).toFixed(0),
        toWei(withSlippageLp, principalToken.decimals).toFixed(0),
        account,
        discount,
        deadline,
      ]
      const optionsContract = getOptionsContract(web3)
      try {
        await sendContract(dispatch, key, stakeuuid, optionsContract, 'exerciseLp', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Redemeed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onExcerciseLp: handleExcercise, pending }
}

export { useExcercise, useExcerciseVe, useExcerciseLp, useExcercisebveLynx }
