import React, { useMemo, useState } from 'react'
import { isAddress } from '@ethersproject/address'
import { useDelegate } from '../../../hooks/useLock'
import { delegatees } from '../../../config/constants/managers'
import DropDown from '../../../components/common/Dropdown'
import { upperFirst } from 'lodash'
import { TransactButton } from '../../common/Buttons/transactButton'

const DelegateTab = ({ selected }) => {
  const [address, setAddress] = useState('')
  const { onDelegate, pending } = useDelegate()
  const delegateeAddress = delegatees.map((delegatee) => {
    return { type: delegatee.address, display: upperFirst(delegatee.title) }
  })

  const errorMsg = useMemo(() => {
    if (!address) {
      return 'ENTER ADDRESS'
    }
    if (!isAddress(address)) {
      return 'INVALID ADDRESS'
    }
    return null
  }, [address])

  return (
    <>
      <div className='mt-5'>
        <p className='text-white text-md md:text-xl font-medium'>Delegate veLYNX to</p>
        <p className='mt-[13px] text-white text-sm md:text-md font-medium'>Address</p>
        <div className='mt-1.5 lg:mt-2.5  p-px w-full'>
          <div className='bg-white/5 px-3 rounded-md flex items-center justify-between'>
            <input
              className={`bg-transparent w-full py-3 text-xl lg:text-[20px] font-medium leading-10 placeholder-[#757384] text-white focus:!border-themeOrange focus:!border-[0px] block focus-visible:!outline-none`}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              placeholder='0x...'
            />
          </div>
        </div>
      </div>
      <p className='mt-[13px] text-white text-sm md:text-md font-medium'>Or select a delegatee</p>
      <DropDown className={'mt-1 md:mt-[10px]'} options={delegateeAddress} selected={address} setSelected={setAddress} fullWidth />
      <TransactButton
        disabled={errorMsg || pending}
        pending={pending}
        onClickHandler={() => {
          onDelegate(selected.id, address)
        }}
        content={errorMsg || 'DELEGATE'}
        className='py-3 mt-5 text-sm md:text-lg px-[19px] w-full'
      />
    </>
  )
}

export default DelegateTab
