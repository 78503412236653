import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { getAllowance, sendContract } from '../utils/api'
import { getERC20Contract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { fromWei, toWei } from '../utils/formatNumber'
import { getTHEAddress, getVeTHEAddress } from '../utils/addressHelpers'
import moment from 'moment'
import { useV3Voter, useVeDist, useVeTHE } from './useContract'
import { truncateEthAddress } from '../utils'

const useCreateLock = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()

  const handleCreate = useCallback(
    async (amount, unlockTime, isPermanent) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const createuuid = uuidv4()
      const unlockString = moment().add(unlockTime, 'seconds').format('YYYY/MM/DD')
      dispatch(
        openTransaction({
          key,
          title: `Lock LYNX until ${unlockString}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve LYNX`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [createuuid]: {
              desc: `Lock your tokens`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isApproved = true
      const tokenContract = getERC20Contract(web3, getTHEAddress())
      const allowance = await getAllowance(tokenContract, getVeTHEAddress(), account)
      if (fromWei(allowance).lt(amount)) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getVeTHEAddress(), toWei(amount).toFixed(0)], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [toWei(amount).toFixed(0), unlockTime, isPermanent]
      try {
        await sendContract(dispatch, key, createuuid, veTHEContract, 'createLock', params, account)
      } catch (err) {
        console.log('create lock error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Locking Successful',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract],
  )

  return { onCreateLock: handleCreate, pending }
}

const useIncreaseAmount = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()

  const handleIncreaseAmount = useCallback(
    async (id, amount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const increaseuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Increase lock amount on veLYNX #${id}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve LYNX`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [increaseuuid]: {
              desc: `Increase your lock amount`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isApproved = true
      const tokenContract = getERC20Contract(web3, getTHEAddress())
      const allowance = await getAllowance(tokenContract, getVeTHEAddress(), account)
      if (fromWei(allowance).lt(amount)) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [getVeTHEAddress(), toWei(amount).toFixed(0)], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [id, toWei(amount).toFixed(0)]
      try {
        await sendContract(dispatch, key, increaseuuid, veTHEContract, 'increaseAmount', params, account)
      } catch (err) {
        console.log('increase amount error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Lock Amount Increased',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract],
  )

  return { onIncreaseAmount: handleIncreaseAmount, pending }
}

const useIncreaseDuration = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()

  const handleIncreaseDuration = useCallback(
    async (id, unlockTime, isPermanent) => {
      const key = uuidv4()
      const increaseuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Increase unlock time on veLYNX #${id}`,
          transactions: {
            [increaseuuid]: {
              desc: `Increase your lock duration`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      const end = Math.floor((new Date().getTime() / 1000 + unlockTime) / 604800) * 604800
      console.log('unlockTime :>> ', unlockTime)
      console.log('end :>> ', end)
      const params = [id, unlockTime, isPermanent]
      try {
        await sendContract(dispatch, key, increaseuuid, veTHEContract, 'increaseUnlockTime', params, account)
      } catch (err) {
        console.log('increase duration error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Lock Duration Increased',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract],
  )

  return { onIncreaseDuration: handleIncreaseDuration, pending }
}

const useUnlockPermanent = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()

  const handleUnlockPermanent = useCallback(
    async (id) => {
      const key = uuidv4()
      const increaseuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Unlock permanent veLYNX #${id}`,
          transactions: {
            [increaseuuid]: {
              desc: `Start unlock time`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      const params = [id]
      try {
        await sendContract(dispatch, key, increaseuuid, veTHEContract, 'unlockPermanent', params, account)
      } catch (err) {
        console.log('unlock error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Permalock Undone',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract],
  )

  return { onUnlock: handleUnlockPermanent, pending }
}

const useWithdraw = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const voterContract = useV3Voter()
  const veDistContract = useVeDist()

  const handleWithdraw = useCallback(
    async (veTHE) => {
      const key = uuidv4()
      const resetuuid = uuidv4()
      const withdrawuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Withdraw lock amount on veLYNX #${veTHE.id}`,
          transactions: {
            [resetuuid]: {
              desc: `Reset votes`,
              status: TransactionType.START,
              hash: null,
            },
            [withdrawuuid]: {
              desc: `Withdraw lock amount`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isReset = true
      if (veTHE.voted) {
        isReset = false
        try {
          await sendContract(dispatch, key, resetuuid, voterContract, 'reset', [veTHE.id], account)
        } catch (err) {
          console.log('reset error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isReset) {
        dispatch(
          updateTransaction({
            key,
            uuid: resetuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      try {
        await sendContract(dispatch, key, withdrawuuid, veTHEContract, 'claim', [veTHE.id], account)
      } catch (err) {
        console.log('withdraw error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Lock Withdrawn',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract, veDistContract],
  )

  return { onWithdraw: handleWithdraw, pending }
}

const useMerge = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const voterContract = useV3Voter()
  const veDistContract = useVeDist()

  const handleMerge = useCallback(
    async (from, to) => {
      const key = uuidv4()
      const resetuuid = uuidv4()
      const claimuuid = uuidv4()
      const withdrawuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Merge veLYNX #${from.id} to veLYNX #${to.id}`,
          transactions: {
            [resetuuid]: {
              desc: `Reset votes for veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
            [claimuuid]: {
              desc: `Claim rebase for veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
            [withdrawuuid]: {
              desc: `Merge veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isReset = true
      if (from.voted) {
        isReset = false
        try {
          await sendContract(dispatch, key, resetuuid, voterContract, 'reset', [from.id], account)
        } catch (err) {
          console.log('reset error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isReset) {
        dispatch(
          updateTransaction({
            key,
            uuid: resetuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      let isClaimed = true
      if (from.rebase_amount.gt(0)) {
        isClaimed = false
        try {
          await sendContract(dispatch, key, claimuuid, veDistContract, 'claim', [from.id], account)
        } catch (err) {
          console.log('claim error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isClaimed) {
        dispatch(
          updateTransaction({
            key,
            uuid: claimuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      try {
        await sendContract(dispatch, key, withdrawuuid, veTHEContract, 'merge', [from.id, to.id], account)
      } catch (err) {
        console.log('withdraw error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Merge Successful',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract, veDistContract],
  )

  return { onMerge: handleMerge, pending }
}

const useSplit = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const voterContract = useV3Voter()
  const veDistContract = useVeDist()

  const handleSplit = useCallback(
    async (from, weights) => {
      const key = uuidv4()
      const resetuuid = uuidv4()
      const claimuuid = uuidv4()
      const splituuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Split veLYNX #${from.id}`,
          transactions: {
            [resetuuid]: {
              desc: `Reset votes for veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
            [claimuuid]: {
              desc: `Claim rebase for veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
            [splituuid]: {
              desc: `Split veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isReset = true
      if (from.voted) {
        isReset = false
        try {
          await sendContract(dispatch, key, resetuuid, voterContract, 'reset', [from.id], account)
        } catch (err) {
          console.log('reset error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isReset) {
        dispatch(
          updateTransaction({
            key,
            uuid: resetuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      let isClaimed = true
      if (from.rebase_amount.gt(0)) {
        isClaimed = false
        try {
          await sendContract(dispatch, key, claimuuid, veDistContract, 'claim', [from.id], account)
        } catch (err) {
          console.log('claim error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isClaimed) {
        dispatch(
          updateTransaction({
            key,
            uuid: claimuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const splitArr = weights.map((weight) => {
        return (weight * 10) ^ 6
      })

      try {
        await sendContract(dispatch, key, splituuid, veTHEContract, 'split', [splitArr, from.id], account)
      } catch (err) {
        console.log('split error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Split Successful',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract, veDistContract],
  )

  return { onSplit: handleSplit, pending }
}

const useTransfer = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const voterContract = useV3Voter()
  const veDistContract = useVeDist()

  const handleTransfer = useCallback(
    async (from, to) => {
      const key = uuidv4()
      const resetuuid = uuidv4()
      const claimuuid = uuidv4()
      const transferuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Transfer veLYNX #${from.id}`,
          transactions: {
            [resetuuid]: {
              desc: `Reset votes for veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
            [claimuuid]: {
              desc: `Claim rebase for veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
            [transferuuid]: {
              desc: `Transfer veLYNX #${from.id}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isReset = true
      if (from.voted) {
        isReset = false
        try {
          await sendContract(dispatch, key, resetuuid, voterContract, 'reset', [from.id], account)
        } catch (err) {
          console.log('reset error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isReset) {
        dispatch(
          updateTransaction({
            key,
            uuid: resetuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      let isClaimed = true
      if (from.rebase_amount.gt(0)) {
        isClaimed = false
        try {
          await sendContract(dispatch, key, claimuuid, veDistContract, 'claim', [from.id], account)
        } catch (err) {
          console.log('claim error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isClaimed) {
        dispatch(
          updateTransaction({
            key,
            uuid: claimuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      try {
        await sendContract(dispatch, key, transferuuid, veTHEContract, 'transferFrom', [account, to, from.id], account)
      } catch (err) {
        console.log('withdraw error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Transfer Successful',
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract, veDistContract],
  )

  return { onTransfer: handleTransfer, pending }
}

const useDelegate = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()

  const handleDelegate = useCallback(
    async (id, to) => {
      const isUndelegate = to.toLowerCase() === account.toLowerCase()
      const key = uuidv4()
      const transferuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `${isUndelegate ? 'Undelegate' : 'Delegate'} veLYNX #${id}`,
          transactions: {
            [transferuuid]: {
              desc: `Delegate veLYNX #${id} to ${truncateEthAddress(to)}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)

      try {
        await sendContract(dispatch, key, transferuuid, veTHEContract, 'delegate', [id, to], account)
      } catch (err) {
        console.log('withdraw error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: `${isUndelegate ? 'Undelegate' : 'Delegate'} Successful`,
        }),
      )
      setPending(false)
    },
    [account, web3, veTHEContract],
  )

  return { onDelegate: handleDelegate, pending }
}

const useReset = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const voterContract = useV3Voter()

  const handleReset = useCallback(async () => {
    const key = uuidv4()
    const resetuuid = uuidv4()
    dispatch(
      openTransaction({
        key,
        title: `Reset Votes`,
        transactions: {
          [resetuuid]: {
            desc: `Reset your votes`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    setPending(true)
    try {
      await sendContract(dispatch, key, resetuuid, voterContract, 'reset', [], account)
    } catch (err) {
      console.log(err)
      console.log('reset error :>> ', err)
      setPending(false)
      return
    }

    dispatch(
      completeTransaction({
        key,
        final: 'Reset Successful',
      }),
    )
    setPending(false)
  }, [account, web3, veTHEContract, voterContract])

  return { onReset: handleReset, pending }
}

const usePoke = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veTHEContract = useVeTHE()
  const voterContract = useV3Voter()

  const handlePoke = useCallback(async () => {
    const key = uuidv4()
    const resetuuid = uuidv4()
    dispatch(
      openTransaction({
        key,
        title: `Cast your Votes`,
        transactions: {
          [resetuuid]: {
            desc: `Casting saved votes`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    setPending(true)
    try {
      await sendContract(dispatch, key, resetuuid, voterContract, 'poke', [], account)
    } catch (err) {
      console.log(err)
      console.log('poke error :>> ', err)
      setPending(false)
      return
    }

    dispatch(
      completeTransaction({
        key,
        final: 'Cast Successful',
      }),
    )
    setPending(false)
  }, [account, web3, veTHEContract, voterContract])

  return { onPoke: handlePoke, pending }
}

export {
  useCreateLock,
  useIncreaseAmount,
  useIncreaseDuration,
  useWithdraw,
  useMerge,
  useSplit,
  useTransfer,
  useReset,
  useUnlockPermanent,
  useDelegate,
  usePoke,
}
