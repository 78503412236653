import React from 'react'

const WarningTag = ({ text, classname, style }) => {
  return (
    <div className={`flex flex-row w-full items-center space-x-2 mt-4 lg:mt-0 ${classname || ''}`}>
      <img alt='warning-icon' src='/images/svgs/warning.svg' />
      <p className={`text-white font-semibold bg-themeOrange/50 text-[12px] p-2 rounded ${style || ''}`}>{text}</p>
    </div>
  )
}

export default WarningTag
