import React from 'react'
import { TokenAmountCard } from '../components/TokenAmountCard'
import { Field } from '../../../../../../state/mintV3/actions'
import { maxAmountSpend } from '../../../../../../v3lib/utils/utils'
import { useV3MintActionHandlers, useV3MintState } from '../../../../../../state/mintV3/hooks'

export const EnterAmounts = ({ currencyA, currencyB, mintInfo, liquidityRangeType, selectedPreset }) => {
  const { independentField, typedValue } = useV3MintState()

  // TODO: Enhance this
  const allowedA =
    selectedPreset?.manual ||
    currencyA?.wrapped?.address?.toLowerCase() === selectedPreset?.allowed0?.address?.toLowerCase() ||
    currencyA?.wrapped?.address?.toLowerCase() === selectedPreset?.allowed1?.address?.toLowerCase()
  const allowedB =
    selectedPreset?.manual ||
    currencyB?.wrapped?.address?.toLowerCase() === selectedPreset?.allowed0?.address?.toLowerCase() ||
    currencyB?.wrapped?.address?.toLowerCase() === selectedPreset?.allowed1?.address?.toLowerCase()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers(mintInfo.noLiquidity)

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [mintInfo.dependentField]: mintInfo.parsedAmounts[mintInfo.dependentField]?.toExact() ?? '',
  }

  // get the max amounts user can add
  const maxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(mintInfo.currencyBalances[field]),
    }
  }, {})

  return selectedPreset ? (
    <div className='mt-4 md:mt-5'>
      <p className='text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Deposit Amounts</p>
      <div className='mt-3' style={{ display: allowedA ? 'block' : 'none' }}>
        <TokenAmountCard
          currency={currencyA}
          value={formattedAmounts[Field.CURRENCY_A]}
          handleInput={onFieldAInput}
          maxAmount={maxAmounts[Field.CURRENCY_A]}
          locked={mintInfo.depositADisabled}
          liquidityRangeType={liquidityRangeType}
        />
      </div>
      <div className='mt-3' style={{ display: allowedB ? 'block' : 'none' }}>
        <TokenAmountCard
          currency={currencyB}
          value={formattedAmounts[Field.CURRENCY_B]}
          handleInput={onFieldBInput}
          maxAmount={maxAmounts[Field.CURRENCY_B]}
          locked={mintInfo.depositBDisabled}
          liquidityRangeType={liquidityRangeType}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
